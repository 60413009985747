import React from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
//import { Link } from "gatsby"
import Banner from "../components/Banner"
import About from "../components/Home/About"
import Tips from "../components/Home/Tips"
import ZipCodeSearch from "../components/Search/ZipCodeSearch"
import { graphql } from 'gatsby'
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import FeaturedPlaces from "../components/Home/FeaturedPlaces"
import SEO from "../components/SEO"
// import { BrowserRouter as router} from "react-router-dom"
import styles from "../css/survey.module.css";
import { navigate } from "gatsby"

export const query = graphql`
query {
    defaultBcg: file(relativePath: {eq: "banner.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;
function callSurvey(){
 // alert(' I am called');
    navigate('/survey', {
        replace: true
    });
}
export default ({ data }) => (

    <Layout>
        <SEO title="Home" />
        <StyledHero home="true" img={data.defaultBcg.childImageSharp.fluid}>
            <Banner
                title="Get The Care"
                tag = "A Nonprofit"
                info="Find a rehab closer to home or location of your choice."
            >
                <button className={styles.logoBtn} onClick={callSurvey}> Check your "Quality of Life" score here</button>
                <ZipCodeSearch/>
            </Banner>
            {/*{navigate("/survey")}*/}
        </StyledHero>
        <zipcode />
        <About />
        <Tips />
        <FeaturedPlaces />
    </Layout>
)
