import React from "react"
import Title from "../Title"
import styles from "../../css/about.module.css"
//import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
// import AniLink from "../../pages";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getAbout = graphql`
query aboutImage{
  aboutImage: file(relativePath: {eq: "replace-sample.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
}
`;

const About = () => {
  const { aboutImage } = useStaticQuery(getAbout);
  return (
    <section className={styles.about}>
      <Title title="about" subtitle="Get The Care" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="about company" /> */}
            <Img fluid={aboutImage.childImageSharp.fluid} alt="landscape" />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>Get The Care is here to help.</h4>
          <p>
             We have seen family and friends impacted deeply by substance abuse and
              mental health issues with their loved ones. Get The Care provides two important service
              <br/><br/>
              <ul className={styles.core}>
                  <li>
                      Patients or families can search for a treatment center or rehab that interests them.
                  </li>
                  <li>
                      <b>Family and Friends can donate to their treatment.</b> The money collected for the treatment
                      is given to a specific rehab in the name of the patient. <u>No money is given to the patient.</u>
                  </li>
              </ul>
          </p>
          <p>
            There are thousands of rehabs but for someone to find the right one that is difficult.
            We make it easy to find by zip code and/or preferences. You can then make an informed decison for yourself and your family.
           </p>
            <p>
                Encourage the person suffering to seek help and let family and friends be part of their lives by donating so we can all join hands in removing the scourge of addiction.
            </p>
            <p>
                Lets save lives. Lets give hope. Lets save souls.
            </p>
            <p>
                It can impact any families and communities. Remember the addict is a victim they need our help.
            </p>
          {/*<AniLink paintDrip hex="#AEECEE" to="/places" className="btn-white">Let The Journey Begin</AniLink>*/}
        </article>
      </div>
    </section>
  )
}

export default About
