import React from "react"
import { FaWallet, FaCamera, FaSocks } from "react-icons/fa"

export default [
  {
    icon: <FaWallet />,
    title: "no patient brokering",
    text: "Avoid services from folks not directly involved with the rehab. They are selling you for a commission.",
  },
  {
    icon: <FaCamera />,
    title: "do your research",
    text: "Do a search of the rehab and the management. Any flags investigate it before you sign up",
  },
  {
    icon: <FaSocks />,
    title: "ask for outcome",
    text: "Ask them for outcome and whats their typical return rate.",
  },
]
